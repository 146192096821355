:root {
  --blue: #382e92;
  --original-gray: #646769;
  --gray: #525455;
  --light-gray: #f4f4f6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: Helvetica, sans-serif;
  color: var(--gray);
}

html {
  scroll-behavior: smooth;
}
