.navbar {
  display: flex;
  justify-content: space-around;
  font-weight: 300;
}

.navbar a {
  min-width: 80px;
  text-align: center;
  text-decoration: none;
  color: var(--gray);
}

a:hover {
  color: var(--blue);
  font-weight: 500;
}

@media all and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 0;
  }

  .navbar a {
    padding: 1rem 2rem 1rem 2rem;
    font-size: 1.5rem;
    text-align: start;
  }
}
