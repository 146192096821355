.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  padding: 1rem 2rem 1rem 2rem;
}

.logo {
  width: 200px;
  height: 80px;
}

.logo img {
  width: 100%;
  height: auto;
}

.nav {
  display: inline;
  align-self: center;
}

.hamburger {
  display: none;
}

@media all and (max-width: 320px) {
  .logo {
    width: 170px;
  }
}

@media all and (max-width: 768px) {
  .hamburger {
    display: block;
    align-content: center;
    text-align: end;
    fill: var(--gray);
  }

  .hamburger:hover {
    fill: var(--blue);
  }
  .nav {
    display: none;
  }

  .nav-mobile {
    display: block;
    grid-column-start: 1;
    grid-column-end: end;
  }
}
