.about-main::before {
  display: block;
  background-color: var(--blue);
  content: " ";
  width: 100%;
  height: 10px;
  position: absolute;
}

.about-main {
  display: flex;
  flex-direction: column;
  background-color: var(--light-gray);
}

.about-header {
  position: relative;
  background-color: var(--light-gray);
  width: 100%;
  height: 100px;
  margin-top: 3rem;
}

.about-header h2 {
  position: absolute;
  top: 30%;
  margin: 0;
  min-width: 100%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 300;
  color: var(--blue);
}

.about-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: var(--light-gray);
  padding: 3rem 2rem;
}

.about-title h2 {
  font-size: 2.2rem;
  font-weight: 300;
  margin: 0;
  text-align: center;
}

.about-title h3 {
  margin: 0;
  font-weight: 200;
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.4;
  padding-top: 1rem;
}

.about-image {
  /* background-repeat: no-repeat;
  background-size: cover; */
  height: 300px;
  overflow: hidden;
  /* margin-left: 2rem; */
}

img {
  max-width: 100%;
  height: auto;
}

.about-description {
  background-color: var(--light-gray);
  padding: 1rem;
}

.about-description p {
  font-size: 1.1rem;
  line-height: 1.6;
  letter-spacing: 0.03rem;
  padding: 0;
  /* padding: 1.5rem; */
}

@media all and (max-width: 320px) {
  .about-header h2 {
    font-size: 1.5rem;
  }

  .about-image {
    height: 250px;
  }

  .about-description p {
    font-size: 0.8rem;
    font-weight: 300;
    padding: 0;
  }
}

@media all and (min-width: 500px) {
  .about-image {
    height: 450px;
  }
}

@media all and (min-width: 800px) {
  .about-title h2 {
    font-size: 2.8rem;
  }
  .about-image {
    height: 500px;
  }

  .about-description p {
    padding: 2rem;
  }
}

@media all and (min-width: 1024px) {
  .about-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "image title"
      "image description";
    justify-items: center;
  }

  .about-header {
    grid-area: header;
  }

  .about-title {
    grid-area: title;
    display: flex;
    flex-direction: colunm;
    justify-content: flex-end;
    padding: 3rem 0rem 0rem 0rem;
  }

  .about-title h2,
  .about-title h3 {
    padding: 0.5rem 0 0 0;
    text-align: start;
    max-width: 400px;
  }

  .about-image {
    background-color: white;
    grid-area: image;
    height: 550px;
    padding: 4rem 0 0 0rem;
  }

  .about-description {
    grid-area: description;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding: 2rem 0rem; */
  }

  .about-description p {
    padding: 0 0 0.5rem 0;
    text-align: start;
    max-width: 400px;
  }
}

@media all and (min-width: 1200px) {
  .about-header {
    height: 150px;
  }

  /* .about-title h2,
  .about-title p {
    padding: 0.5rem 0rem;
  } */

  /* .about-description p {
    font-size: 1.2rem;
  } */

  .about-image {
    height: 600px;
  }
}

@media all and (min-width: 1600px) {
  /* .about-title h2,
  .about-title p {
    margin: 0 20%;
  }

  .about-description p {
    margin: 0 20%;
  } */

  .about-image {
    height: 700px;
  }
}

@media all and (min-width: 2000px) {
  .about-image {
    height: 800px;
  }
}

@media all and (min-width: 2500px) {
  .about-image {
    height: 950px;
  }
}

@media all and (min-width: 3000px) {
  .about-main::before {
    width: 3000px;
  }
}
