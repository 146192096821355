.title {
  margin: 5rem 2rem;
}

.title h2 {
  font-size: 1.8rem;
  font-weight: 200;
}

.content {
  margin: 1rem 2rem;
}

.content > * {
  padding: 1rem 0rem;
}

.content h3 {
  font-size: 1.2rem;
  font-weight: 400;
}

.content h4 {
  font-size: 1rem;
}

.content p {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.6;
  text-align: justify;
}

.content li {
  font-size: 0.8rem;
  font-weight: 300;
  margin-left: 1rem;
}

@media all and (max-width: 320px) {
  .title h2 {
    font-size: 1.4rem;
  }

  .content p {
    font-size: 0.6rem;
  }
}

@media all and (min-width: 1024px) {
  .wrapper {
    display: flex;
    justify-content: center;
  }

  .legal {
    max-width: 1024px;
  }
}
