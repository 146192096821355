.footer {
  display: grid;
  background-color: var(--blue);
  color: white;
}

.footer > * {
  justify-self: center;
  text-align: center;
}

.title h2 {
  margin: 4rem 0rem;
  font-size: 3rem;
  font-weight: 300;
}

.address > * {
  margin: 1rem 0rem;
}

.address h3 {
  font-size: 1.5rem;
  font-weight: 400;
}

.address h4 {
  font-size: 1.5rem;
  font-weight: 300;
}

.address p {
  font-size: 1rem;
  padding: 0;
}

.links {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.links a {
  padding: 0.5rem;
  color: white;
  text-decoration: none;
}

.copyright {
  margin: 3rem 0rem;
}
.copyright p {
  font-size: 1rem;
}

/* @media all and (min-width: 769px) {
  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "title title"
      "address links"
      "copyright copyright";
  }
  .footer > * {
    margin-left: 3rem;
    justify-self: left;
  }

  .address {
    grid-area: address;
    align-content: flex-end;
  }

  .address > * {
    text-align: left;
  }

  .links {
    grid-area: links;
    flex-direction: column;
    justify-content: end;
    justify-self: center;
    margin: 0.5rem 0;
  }
  .links a {
    align-self: center;
  }
  .copyright {
    grid-area: copyright;
  }
} */
