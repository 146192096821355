/* .services {
  margin-top: 10%;
} */

.services-title {
  position: relative;
  background-color: var(--blue);
  width: 100%;
  height: 100px;
}

.services-title h2 {
  position: absolute;
  top: 30%;
  margin: 0;
  min-width: 100%;
  text-align: center;
  color: white;
  font-size: 2rem;
  font-weight: 300;
}

.services-items {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--light-gray);
  padding: 2rem;
}

.item > * {
  text-align: center;
  padding: 0.5rem;
}

.icon {
  font-family: inherit;
  font-size: 3rem;
}

.itm-title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
  /* text-align: center; */
}

.itm-descr {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.03rem;
}

/* .description {
  margin: 5rem 2.5rem;
}

.description p {
  padding: 1rem;
  font-size: 1.7rem;
} */

@media all and (max-width: 320px) {
  .services-title h2 {
    font-size: 1.5rem;
  }

  .item {
    padding: 1rem;
  }
}

@media all and (min-width: 1024px) {
  /* .services {
    margin-top: 30%;
  } */

  .services-title {
    position: relative;
    width: auto;
    margin: 0;
    height: 300px;
  }

  .services-title h2 {
    text-align: center;
  }

  /* @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 100%;
    }
  } */

  .services-items {
    position: relative;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    column-gap: 10%;
    top: -100px;
    flex-direction: row;
    padding: 0rem 7rem;
    width: 100%;
  }

  .item {
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    margin: 1rem;
    width: 100%;
    max-width: 500px;
  }

  .item > * {
    text-align: start;
  }

  .item-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1.2rem;
  }

  .item-text > * {
    text-align: start;
  }

  /* .in-view {
    animation: fade-in 4s;
  } */
  /* .desktop {
    height: 100px;
    margin: 0rem 0rem;
  } */

  .description {
    margin: 0;
    margin-bottom: 5rem;
  }

  .description p {
    line-height: 1.6;
    padding: 2rem 7rem;
  }
}

@media all and (min-width: 1800px) {
  .services-items {
    padding: 0 20%;
  }
}
