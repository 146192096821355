.mobile-nav {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 3rem 3rem 3rem;
}

.close-btn {
  all: unset;
  text-align: end;
  font-size: 1.5rem;
  font-weight: 200;
  padding-bottom: 2rem;
}

.close-btn:hover {
  font-weight: 500;
  color: var(--blue);
}
