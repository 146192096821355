.text {
  margin: 3rem 2rem;
  text-align: center;
}

.text > * {
  padding: 2rem;
}

.text h2 {
  font-size: 2rem;
  font-weight: 200;
}

.list {
  margin: 0 30%;
}

.list li {
  list-style-type: circle;
  font-size: 1.5rem;
  font-weight: 200;
  padding: 1rem;
}
