.home {
  display: flex;
  flex-direction: column;
}

.image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  /* width: 100%;
  overflow: hidden; */
  /* order: 2; */
}

/* img {
  max-width: 100%;
  height: auto;
} */

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--light-gray);
  padding: 10% 5%;
  /* order: 1; */
}

h1 {
  font-size: 3.5rem;
  font-weight: 300;
  width: 100%;
  /* padding: 3rem 2rem 1rem 2rem; */
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
}

p {
  font-size: 1.5rem;
  font-weight: 200;
  letter-spacing: 0.04rem;
  padding-top: 1.5rem;
  text-align: center;
}

.languages {
  font-size: 2.5rem;
  font-weight: 400;
  padding-top: 0;
  padding: 0rem 0.5rem;
  /* padding-top: 3rem; */
}

.logo {
  font-weight: 400;
}

.letter {
  color: var(--blue);
}

.bold {
  font-weight: 500;
}

.subintro-text p {
  padding: 1rem;
}

@media all and (max-width: 320px) {
  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1rem;
  }
  .languages {
    font-size: 1.5rem;
  }
}

@media all and (min-width: 500px) {
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media all and (min-width: 1024px) {
  .home {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    height: 750px;
  }

  .image {
    /* order: 1; */
    height: auto;
  }

  .intro {
    padding: 20% 10%;
    /* order: 2; */
  }
}

@media all and (min-width: 3000px) {
  .home {
    height: 1000px;
  }
}

@media all and (min-width: 4000px) {
  .home {
    height: 1500px;
  }
}
