.wrapper::before {
  display: block;
  background-color: var(--blue);
  content: " ";
  width: 100%;
  height: 10px;
}

.title {
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;
  margin: 3rem 2rem;
  color: var(--blue);
}

.values {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0rem 0rem 2rem 0rem;
}

.values > * {
  padding: 3rem;
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;
}

.value1 {
  background-color: #f9f9fa;
}

.value2 {
  background-color: var(--light-gray);
}

.value3 {
  background-color: #e9e9eb;
}

.value4 {
  background-color: #e1e1e4;
}

.icon {
  color: var(--blue);
  font-weight: 800;
  font-size: 2rem;
  padding-bottom: 1.5rem;
}

@media all and (max-width: 450px) {
  .values > * {
    font-size: 1.5rem;
  }
}

@media all and (min-width: 1200px) {
  .title {
    margin: 6rem;
  }

  .values {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 6rem 0rem;
  }
}
